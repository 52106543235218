<script>
import api from "@/helpers/api-rest/api.js";


export default {
  props: {
    id: { type: String, default: "ModalActionEssay" },
    actionType: { type: String, default: '' },
    essayId: { type: Number },
    action: {type: Object, default: () => {
      return {
          description: '',
          essay_id: null,
      }
    }}
  },
  data() {
    return {
      loading: false
    };
  },
  computed: {
    modalTitle() {
      switch (this.actionType) {
        case 'approve':
          return 'Aprobar proyecto';
        case 'return':
          return 'Devolver proyecto';
        case 'reject':
          return 'Rechazar proyecto';
        default:
          return '';
      }
    },
    actionDescription() {
      switch (this.actionType) {
        case 'approve':
          return 'aprueba';
        case 'return':
          return 'devuelve';
        case 'reject':
          return 'rechaza';
        default:
          return '';
      }
    },
  },
  methods: {
    getEndpoint() {
      switch (this.actionType) {
        case 'approve':
          return '/api/v1/essay-approvals';
        case 'return':
          return '/api/v1/essay-returns';
        case 'reject':
          return '/api/v1/essay-rejected';
        default:
          return null;
      }
    },
    submitDescription(){
      this.loading = true;
      this.action.essay_id = this.essayId;
      this.action.actionType = this.actionType;
      const apiEndpoint = this.getEndpoint();
      api.post({url:apiEndpoint, data:this.action})
      .then(response => {
          this.loading = false;
          const newAction = response?.data?.data;
          this.$emit('success', newAction);
          this.$bvModal.hide(this.id);
      })
      .catch(error => {
          this.loading = false;
          console.error("Error al crear la accion:", error);
      });
    },
    cancelForm(){
      this.$bvModal.hide(this.id);
    }
  },
};
</script>
<template>
  <b-modal :id="id" :title="modalTitle" scrollable size="extra-xl">
    <template #default="{ }">
      <div class="card">
        <div class="card-body">
          <b-alert show>
            <b-icon icon="exclamation-circle-fill" class="mr-2" variant="info"></b-icon>
            Describe el motivo por el que se {{ actionDescription }} el proyecto. <b>Este motivo se le enviará vía email al coordinador.</b>
          </b-alert>
          <div class="row">
            <div class="col-lg-12">
              <div class="form-group">
                <label for="subject">Descripción:</label>
                <textarea v-model="action.description" class="form-control" id="subject" rows="3"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template #modal-footer="{  }">
      <b-button @click="submitDescription" variant="primary" :disabled="loading"><span class="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true" v-if="loading"></span>Enviar</b-button>
      <b-button @click="cancelForm()">Cancelar</b-button>
      <!-- <div class="d-flex justify-content-center w-100">
      </div> -->
    </template>
  </b-modal>
</template>